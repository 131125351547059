import React from "react"
import classNames from "classnames"
import ReactPaginate from "react-paginate"
import tw from "twin.macro"

import Icons from "@components/v3/Icons"
import { css } from "@emotion/css"

const sizes = {
    sm: {
        pageCss: css`
            ${tw`w-[34px] h-[34px]`}
            ${tw`title-5`}
        `
    },
    md: {
        pageCss: css`
            ${tw`w-[40px] h-[40px]`}
            ${tw`title-4`}
        `
    }
}

type Size = keyof typeof sizes

const containerCSS = css`
    ${tw`w-fit`}
    ${tw`flex items-center justify-center gap-1`}
`

const pageCSS = (size: Size) => css`
    ${tw`flex items-center justify-center`}
    ${tw`rounded-full`}
    ${tw`font-semibold`}
    ${tw`cursor-pointer`}

    a {
        ${sizes[size].pageCss}
        ${tw`p-[5px]`}
        ${tw`flex items-center justify-center`}
        ${tw`!text-additional hover:!text-teal dark:!text-dark-additional hover:dark:!text-dark-teal`}
    }

    &.disabled {
        ${tw`cursor-not-allowed`}

        a {
            ${tw`hover:!text-[#aaaaaa]`}
        }
    }

    &.selected {
        ${tw`bg-foreground-teal dark:bg-dark-foreground-teal`}

        a {
            ${tw`!text-teal dark:!text-dark-teal`}
        }
    }
`

const arrowCSS = css`
    ${tw`flex`}
    ${tw`cursor-pointer`}
    ${tw`first:mr-2 last:ml-2`}

    a {
        ${tw`w-[34px]`}
        ${tw`flex justify-center`}
        ${tw`!text-icon-contrast hover:!text-dark-icon-primary dark:!text-dark-icon-contrast hover:dark:!text-dark-icon-primary`}
    }

    &.disabled {
        ${tw`!cursor-not-allowed`}

        a {
            ${tw`!cursor-not-allowed`}
            ${tw`!text-icon-disabled dark:!text-dark-icon-disabled`}
        }
    }
`

const breakCSS = css`
    ${tw`flex`}
    ${tw`cursor-pointer`}

    a {
        ${tw`block`}
        ${tw`w-[34px] h-[34px]`}
        ${tw`flex items-end justify-center`}
        ${tw`!text-additional hover:!text-teal dark:!text-dark-additional hover:dark:!text-dark-teal`}
    }
`

interface Props {
    /**
     * The number of pages to display for the pagination component.
     */
    pageCount: number
    /**
     * The current page number.
     */
    page: number
    /**
     * Page change callback.
     * @param page The page number.
     * @returns void
     * @default () => {}
     */
    onPageChange?: (page: number) => void
    /**
     * The size of the pagination component.
     * @default "md"
     */
    size?: Size
}

const Pagination: React.FC<Props> = ({ page, onPageChange, pageCount, size = "md" }) => {
    const previousLabel = <Icons icon='ChevronLeft' width={24} height={24} />
    const nextLabel = <Icons icon='ChevronRight' width={24} height={24} />

    return (
        <ReactPaginate
            forcePage={page ? page - 1 : undefined}
            previousLabel={previousLabel}
            nextLabel={nextLabel}
            onClick={(event) => {
                if (typeof event?.nextSelectedPage !== "undefined") {
                    onPageChange?.(event.nextSelectedPage + 1)
                }
            }}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={pageCount}
            className={classNames("reku-new", containerCSS)}
            pageClassName={pageCSS(size)}
            previousClassName={arrowCSS}
            nextClassName={arrowCSS}
            breakClassName={breakCSS}
        />
    )
}

Pagination.defaultProps = {
    onPageChange: () => {},
    size: "md"
}

export default Pagination
